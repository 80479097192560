import axios from 'axios';
import config from '../config';
import { getBearerToken } from '../util/cookie';
// import { programsSuccess } from './programs.duck';

// ================ Action types ================ //

export const ACTIVITIES_REQUEST = 'app/CoachSaaS/ACTIVITIES_REQUEST';
export const ACTIVITIES_SUCCESS = 'app/CoachSaaS/ACTIVITIES_SUCCESS';
export const ACTIVITIES_ERROR = 'app/CoachSaaS/ACTIVITIES_ERROR';

export const ACTIVITIES_SAVE_REQUEST = 'app/CoachSaaS/ACTIVITIES_SAVE_REQUEST';
export const ACTIVITIES_SAVE_SUCCESS = 'app/CoachSaaS/ACTIVITIES_SAVE_SUCCESS';
export const ACTIVITIES_SAVE_ERROR = 'app/CoachSaaS/ACTIVITIES_SAVE_ERROR';

export const EVENTS_REQUEST = 'app/CoachSaaS/EVENTS_REQUEST';
export const EVENTS_SUCCESS = 'app/CoachSaaS/EVENTS_SUCCESS';
export const EVENTS_ERROR = 'app/CoachSaaS/EVENTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  activities: [],
  activities_error: null,
  activities_fetching: false,

  activitiesSave: null,
  activitiesSaving: null,
  activitiesSavingError: null,

  events: null,
  eventsFetching: false,
  eventsFetchingError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ACTIVITIES_REQUEST:
      return {
        ...state,
        activities_fetching: true,
      };
    case ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities_fetching: false,
        activities: payload,
      };
    case ACTIVITIES_ERROR:
      return {
        ...state,
        activities_fetching: false,
        activities_error: payload,
      };

    case ACTIVITIES_SAVE_REQUEST:
      return {
        ...state,
        activitiesSaving: true,
      };
    case ACTIVITIES_SAVE_SUCCESS:
      return {
        ...state,
        activitiesSaving: false,
        activitiesSave: payload,
      };
    case ACTIVITIES_SAVE_ERROR:
      return {
        ...state,
        activitiesSaving: false,
        activitiesSavingError: payload,
      };

    case EVENTS_REQUEST:
      return {
        ...state,
        eventsFetching: true,
      };
    case EVENTS_SUCCESS:
      return {
        ...state,
        eventsFetching: false,
        events: payload,
      };
    case EVENTS_ERROR:
      return {
        ...state,
        eventsFetching: false,
        eventsFetchingError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const activitiesRequest = () => ({ type: ACTIVITIES_REQUEST });
export const activitiesSuccess = data => ({ type: ACTIVITIES_SUCCESS, payload: data });
export const activitiesError = error => ({ type: ACTIVITIES_ERROR, payload: error });

export const activitiesSaveRequest = () => ({ type: ACTIVITIES_SAVE_REQUEST });
export const activitiesSaveSuccess = data => ({ type: EVENTS_SUCCESS, payload: data });
export const activitiesSaveError = error => ({ type: ACTIVITIES_SAVE_ERROR, payload: error });

export const eventsRequest = () => ({ type: EVENTS_REQUEST });
export const eventsSuccess = data => ({ type: EVENTS_SUCCESS, payload: data });
export const eventsError = error => ({ type: EVENTS_ERROR, payload: error });

// ================ Thunks ================ //

export const getAllActivities = (completedOnly, docId, goTo) => async (dispatch, getState, sdk) => {
  dispatch(activitiesRequest());

  const compOnly = completedOnly ? 'yes' : 'no';

  axios
    .get(`${config.coachSaaSService}activities`, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
        docId: docId,
        goTo: goTo,
        completedOnly: compOnly,
      },
    })
    .then(res => {
      dispatch(activitiesSuccess(res.data));
      // if(res.data.length > 0){
      //   dispatch(activitiesSuccess(res.data));
      // }
      //
      // dispatch(activitiesSuccess(getState().activities.activities));
    })
    .catch(err => {
      console.log(err);
      dispatch(activitiesError(err));
    });
};

export const getClientActivity = id => async (dispatch, getState, sdk) => {
  return axios.get(`${config.coachSaaSService}activities/${id}`, {
    headers: {
      'content-type': 'application/json',
      Authorization: await getBearerToken(sdk),
    },
  });
};

export const addClientActivity = transactionId => async (dispatch, getState, sdk) => {
  dispatch(activitiesSaveRequest());
  const params = {
    transactionId: transactionId,
  };

  const url = `${config.coachSaaSService}activities`;
  return axios
    .post(url, params, {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    })
    .then(res => {
      dispatch(activitiesSaveSuccess(res.data));
    })
    .catch(err => {
      console.log(err);
      dispatch(activitiesSaveError(err));
    });
};

// export const planClientSession = (activityId, sessId, data) => async (dispatch, getState, sdk) => {
//   return axios
//     .post(
//       `${config.coachSaaSService}activities/${activityId}/session/${sessId}/plan`,
//       { date: data },
//       {
//         headers: {
//           'content-type': 'application/json',
//           Authorization: await getBearerToken(sdk),
//         },
//       }
//     )
//     .then(res => {})
//     .catch(err => {
//       console.log(err);
//     });
// };

export const makeSessionOngoing = (activityId, sessId, time) => async (dispatch, getState, sdk) => {
  return axios
    .post(
      `${config.coachSaaSService}activities/${activityId}/session/${sessId}/ongoing`,
      { time: time },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {})
    .catch(err => {
      console.log(err);
    });
};

export const pauseSession = (activityId, sessId, elapsedTime) => async (
  dispatch,
  getState,
  sdk
) => {
  return axios
    .post(
      `${config.coachSaaSService}activities/${activityId}/session/${sessId}/pause`,
      { elapsedTime },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {})
    .catch(err => {
      console.log(err);
    });
};

export const resumeSession = (activityId, sessId, resumedAt) => async (dispatch, getState, sdk) => {
  return axios
    .post(
      `${config.coachSaaSService}activities/${activityId}/session/${sessId}/resume`,
      { resumedAt },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {})
    .catch(err => {
      console.log(err);
    });
};

export const makeSessionClose = (activityId, sessId, closedTime) => async (
  dispatch,
  getState,
  sdk
) => {
  return axios
    .post(
      `${config.coachSaaSService}activities/${activityId}/session/${sessId}/close`,
      { closedTime },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {})
    .catch(err => {
      console.log(err);
    });
};

export const SendSummaryPending = (activityId, sessId) => async (dispatch, getState, sdk) => {
  return axios
    .post(
      `${config.coachSaaSService}activities/${activityId}/session/${sessId}/sendsummarypending`,
      {},
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {
      window.alert('Congratulations You have successfully completed the Session');
    })
    .catch(err => {
      console.log(err);
    });
};

export const sendEmailToClient = (activityId, sessId) => async (dispatch, getState, sdk) => {
  return axios
    .post(
      `${config.coachSaaSService}activities/${activityId}/session/${sessId}/sendclientmail`,
      {},
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {})
    .catch(err => {
      console.log(err);
    });
};

export const saveSessionNotes = (activityId, sessId, notes) => async (dispatch, getState, sdk) => {
  return axios
    .post(
      `${config.coachSaaSService}activities/${activityId}/session/${sessId}/save`,
      { notes: notes },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {})
    .catch(err => {
      console.log(err);
    });
};

export const getMyEvents = () => async (dispatch, getState, sdk) => {
  dispatch(eventsRequest());
  return axios
    .post(
      `${config.coachSaaSService}activities/getMyEvents`,
      {},
      {
        headers: {
          'content-type': 'application/json',
          Authorization: await getBearerToken(sdk),
        },
      }
    )
    .then(res => {
      dispatch(eventsSuccess(res.data));
    })
    .catch(err => {
      console.log(err);
      dispatch(eventsError(err));
    });
};

export const createCustomClientActivity = (customerId, programId) => async (
  dispatch,
  getState,
  sdk
) => {
  return axios.post(
    `${config.coachSaaSService}activities/add_custom_client_activity`,
    {
      customer_id: customerId,
      program_id: programId,
    },
    {
      headers: {
        'content-type': 'application/json',
        Authorization: await getBearerToken(sdk),
      },
    }
  );
};
